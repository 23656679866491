import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import * as Styled from './styles';
import type { Props } from './types';

export default function CardNotificationTag({
  color,
  children,
}: Props): JSX.Element {
  const { customTheme } = useContext(ThemeContext);
  return (
    <Styled.NotificationWrapper $color={color || customTheme.colors.warning100}>
      <Styled.TimerIcon />
      <Styled.Notification>{children}</Styled.Notification>
    </Styled.NotificationWrapper>
  );
}
